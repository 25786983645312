import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Container, Button} from 'react-bootstrap'
import Navbar from "./CommonFiles/Navbar"
import RenderProject from "./ProjectComponents/RenderProject"
import "./Styles/Projects.css"

const Projects = () => {
    const [projectType, setProjectType] = useState('all')
    const [projects, setProjects] = useState({
        all: [],
        webDev: [],
        networks:[],
        AI: [],
        OOP: []
    })

    const [allUrl, webUrl, networksUrl, AIUrl, OOPUrl] =  [ 
        './Api/All.json',
        './Api/WebDev.json',
        './Api/Networks.json',
        './Api/AI.json',
        './Api/OOP.json'
    ]

    const fetchData = async () => {
        // Fetching all data
        const [all, web, networks, AI, OOP] = await Promise.all([
            fetch(allUrl),
            fetch(webUrl),
            fetch(networksUrl),
            fetch(AIUrl),
            fetch(OOPUrl)
        ])

        // Storing the json data
        const allData = await all.json()
        const webData = await web.json()
        const networksData = await networks.json()
        const AIData = await AI.json()
        const OOPData = await OOP.json()

        // Updating state
        setProjects({
            all:allData,
            webDev:webData, 
            networks:networksData, 
            AI:AIData, 
            OOP:OOPData
        })
    }

    useEffect(async () => {
        fetchData()
    }, [])

    return (
        <>
        <Navbar />
        <Container className="projectContainer"> 
            <section fluid className="projectButtonContainer"> 
                <Button fluid className="projectButton" onClick={() => setProjectType('all')}> All Projects </Button>
                <Button fluid className="projectButton" onClick={() => setProjectType('web')}> Web Development </Button>
                <Button fluid className="projectButton" onClick={() => setProjectType('OOP')}> Object Oriented  </Button>
                <Button fluid className="projectButton" onClick={() => setProjectType('networks')}> Computer Networks </Button>
                <Button fluid className="projectButton" onClick={() => setProjectType('AI')}> Artifical Intelligence </Button>
            </section>
            <RenderProject projects={projects} projectType={projectType}/>
        </Container>
        </>
    )
}

export default Projects
