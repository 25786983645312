import React from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const Description = () => {
    const greeting = "Hello, my name is"
    const header = "Mushfiqur Anik."
    const tag = "I am a Software Developer"
    const desc = "I am a recent Computer Science Graduate with over 4+ years of programming experience in projects ranging from complex Java Applications, Full Stack Development, Computer Networks, to Machine Learning."

    return (
        <section className="landingDescSection"> 
            <h1 id="landingGreeting"> {greeting} </h1>
            <h1 id="landingHeader"> {header} </h1>
            <h1 id="landingTag"> {tag} </h1>
            <p id="landingDesc"> {desc} </p>
            <Link className="landingBtn" to="/projects">  View All Projects </Link>
        </section>  
    )
}

export default Description
