import {Container, Row, Col} from 'react-bootstrap'
import Navbar from "./CommonFiles/Navbar"
import Description from "./HomeComponents/Description"
import CarouselSection from "./HomeComponents/CarouselSection"
import "./Styles/Home.css"

const Home = () => {
    return (
           <div className="home">
              <Navbar />
              <Container className="homeContainer">
                <Row> 
                    <Col lg={6}>
                    <Description />
                    </Col>
                    <Col lg={6}>
                    <CarouselSection /> 
                    </Col>
                </Row>
             </Container>
           </div>
    )    
}

export default Home
