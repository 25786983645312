import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import Navbar from "./CommonFiles/Navbar"
import "./Styles/Contact.css"

const Contact = () => {
    const firstPara = "I am currently open to taking any Software Development projects whether it is for a company or a freelance project. For any work-related questions or inquiries contact me using one of the following options and I will get back to you as soon as possible! "
    const email = "contact@mushfiquranik.com"
    
    const handleClick = () => {
      window.open('mailto:contact@mushfiquranik.com');
    }

    return (
        <>
          <Navbar />
          <Container  className="contactContainer">
                <h1 className="contactHeader"> Get in touch! </h1>

                <p id="firstPara" className="contactPara"> 
                  {firstPara}
                </p>

                <p className="contactPara"> 

                   <div className="social">
                     <a onClick={handleClick}>  <i className="far fa-envelope"/> </a>
                     <label> {email} </label>
                   </div>

                   <div className="social">
                     <a href="https://www.linkedin.com/in/mushfiq-anik/">  <i className="fab fa-linkedin"/> </a>
                     <label> Connect With Me </label>
                   </div>

                   <div className="social">
                     <a href="https://github.com/mushfiqur-anik">   <i id="code" className="fab fa-github"/>  </a>
                     <label> View My Code </label>
                   </div>
                </p>
          </Container>
        </>
    )
}

export default Contact
