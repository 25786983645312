import ".././Styles/Projects.css"
import {Container, Button, Row, Col, Image} from 'react-bootstrap'

const ProjectItem = ({projectArr, index}) => {
    return (
        <Row key={index}>
            {projectArr.map((project) => 
                <Col className="projectSection" lg={6} sm={12} key={project.id}>
                   <div className="imgBox">
                       <a href={project.website? project.website:project.github}>
                            <Image className="projectImg" src={project.image} fluid/>
                       </a>
                   </div>
                    
                         <div className="noImg">
                             <h3 className="projectTitle"> {project.title} </h3>
                             <p className="projectDesc">  {project.description} </p>
                             <h5 className="projectTech"> {project.technology} </h5>
                             {project.github && <Button href={project.github} className="projectBtnGit"> <i id="icon" className="fab fa-github"></i>  View Code  </Button>}
                             {project.website && <Button href={project.website} className="projectBtnWeb" variant="success"> <i id="icon" className="fas fa-cloud">  </i> View Website  </Button> }
                        </div>
                </Col>
            )}
        </Row>
    )
}

export default ProjectItem
