import React from "react";
import {Nav, Form, FormControl, Navbar, Alert, Button, NavDropdown} from "react-bootstrap"
import {HashRouter as Router, Link} from 'react-router-dom'
import ".././Styles/Navbar.css"

const NavbarMenu = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" className="navbar-custom">
    <Link className="navbar-brand" to="/"> MA  </Link> 

    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto" >
        <Link className="nav-link" to="/projects"> Projects </Link> 
        <Link className="nav-link" to="/about"> About </Link> 
        <Link className="nav-link" to="/contact"> Contact </Link> 
      </Nav>
    </Navbar.Collapse>
  
  </Navbar>
   
  );
};

export default NavbarMenu;