import { Container } from "react-bootstrap"
import Navbar from "./CommonFiles/Navbar"
import "./Styles/About.css"

const About = () => {
    const firstPara = "Hello, I am a Software Developer with 4+ years of programming experience. My university degree has provided me with a solid foundation in general programming principles which primarily focused on Object Oriented Programming. Also, I have had the opportunity to explore several Computer Science topics ranging from Web Development to Machine Learning."
    const secondPara = "Furthermore, my time as a Software Developer Intern has provided me with an experience to work in high pressure industry settings where I had to build and deploy web applications for clients within the proposed deadline. "
    const thirdPara = "Apart from my university and internship experiences I like to challenge myself and keep myself up to date with the latest technologies. I have taught myself the MERN stack (Mongo, Express, React, Node) by using online resources, and I keep on exploring different technologies in my spare time."

    return (
        <>
          <Navbar />
            <Container className="aboutContainer">
              <h1 className="aboutHeader">  About Me  </h1>
                <p id="firstPara" className="aboutPara">
                    {firstPara}
                </p>
                
                <p className="aboutPara">
                    {secondPara}
                </p>
                
                <p className="aboutPara"> 
                    {thirdPara}
                </p>
            </Container>
        </>
    )
}

export default About
