import ProjectItem from './ProjectItem'
import ".././Styles/Projects.css"

const RenderProject = ({projectType, projects}) => {
    switch (projectType) {
        case 'all':
            return (<>
                {projects.all.map((projectArr, index) => 
                    <ProjectItem projectArr={projectArr} index={index}/>
                )} 
            </>)
            break;

        case 'web':
            return ( <>
                {projects.webDev.map((projectArr, index) => 
                     <ProjectItem projectArr={projectArr} index={index}/>
                )} 
                </>)
            break;

        case 'networks':
            return (<>
                {projects.networks.map((projectArr, index) => 
                    <ProjectItem projectArr={projectArr} index={index}/>
                )} 
                </>)
            break;
            
        case 'AI':
            return ( <>
                {projects.AI.map((projectArr, index) => 
                    <ProjectItem projectArr={projectArr} index={index}/>
                )} 
                </>)
            break;

        case 'OOP':
            return ( <>
                {projects.OOP.map((projectArr, index) => 
                   <ProjectItem projectArr={projectArr} index={index}/>
                )}  
                </>)
            break;
            
        default:
            break;
    }

}

export default RenderProject
