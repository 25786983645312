import 'bootstrap/dist/css/bootstrap.min.css';
import {HashRouter as Router,  Route, Link, Switch} from 'react-router-dom'
import Home from "./Components/Home"
import Projects from "./Components/Projects"
import About from "./Components/About"
import Contact from "./Components/Contact"
import Practice from "./Practice"

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/projects" component={Projects} /> 
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
      </Switch>
  </Router>
    
  );
}

export default App;
