import React from 'react'
import {Carousel} from "react-bootstrap"

const CarouselSection = () => {
    const interval = 2000;
    const img1 = ".././ProjectImages/Interior.png"
    const img2 = ".././ProjectImages/Wonderlake.png"
    const img3 = ".././ProjectImages/NaiveBayes.png"

    return (
        <Carousel>
            <Carousel.Item interval={interval}>
                <img
                    className="d-block w-100"
                    src={img1}
                    alt="First slide"
                 />
            </Carousel.Item>

            <Carousel.Item interval={interval}>
                <img
                    className="d-block w-100"
                    src={img2}
                    alt="Second slide"
                />
            </Carousel.Item>

             <Carousel.Item interval={interval}>
                 <img
                    className="d-block w-100"
                    src={img3}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>              
    )
}

export default CarouselSection
